import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {AppConfigContext} from "../../context/AppConfigContextProvider";
import BaseAppParamDialog from "./BaseAppParamDialog";

export default function GatewayParamDialog(
    {gateway, gatewayMutation, isDialogOpen, closeDialog, initialSettings = {}, confirmedSettings = {}}
) {

    const {gatewayParams} = useContext(AppConfigContext);

    return <BaseAppParamDialog
        appParams={gatewayParams}
        device={gateway}
        deviceMutation={gatewayMutation}
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialog}
        initialSettings={initialSettings}
        confirmedSettings={confirmedSettings}
    />
}

GatewayParamDialog.propTypes = {
    gateway: PropTypes.object,
    gatewayMutation: PropTypes.object,
    isDialogOpen: PropTypes.bool,
    closeDialog: PropTypes.func,
    initialSettings: PropTypes.object,
    confirmedSettings: PropTypes.object
}