import {useQuery} from "@tanstack/react-query";
import {Sensor, getEvents} from "../api";
import {FiletypeCsv} from 'react-bootstrap-icons';
import {Button} from "react-bootstrap";
import Moment from "react-moment";
import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import {NonIdealState, Spinner} from "@blueprintjs/core";
import sensors from "../pages/Sensors";
import moment from "moment";

moment.locale('en', {
    longDateFormat : {
        LT: "h:mm:ss A", // <----------- add :ss
        L: "MM/DD/YYYY",
        l: "M/D/YYYY",
        LL: "MMMM Do YYYY",
        ll: "MMM D YYYY",
        LLL: "MMMM Do YYYY LT",
        lll: "MMM D YYYY LT",
        LLLL: "dddd, MMMM Do YYYY LT",
        llll: "ddd, MMM D YYYY LT"
    }
});

function filterOn(sysStatus) {
    let filter = [];
    const convert2bin = (sysStatus >>> 0).toString(2).split('');
    let reverse_idx = [9,8,7,5];
    let status_bit = [];
    for (const [idx, value] of reverse_idx.entries()){
        if(convert2bin[convert2bin.length - value] === '0' || convert2bin[convert2bin.length - value] === undefined){
            status_bit.push('0')
        } else {
            status_bit.push('1')
        }
    }

    //status_bit[0] => Step Detector prefilter, system_status bit 9
    //status_bit[1] => NN prefilter, system_status bit 8
    //status_bit[2] => Threshold prefilter, system_status bit 7
    //status_bit[3] => Kurtosis prefilter, system_status bit 5

    if (status_bit[0] === '0'){ //Step Detector Failed
        if(status_bit[1] === '1' && status_bit[2] === '1' && status_bit[3] === '1'){
            filter.push('Step Detector Filter');
            return filter
        } else if (status_bit[1] === '0' && status_bit[2] === '1' && status_bit[3] === '0'){
            filter.push('Kurtosis Prefilter');
            return filter
        } else if (status_bit[1] === '0' && status_bit[2] === '0' && status_bit[3] === '0'){
            filter.push('Threshold Prefilter');
            return filter
        } else {
            //Case of unexpected result
            filter.push('error')
            console.log('Error: system status filter')
            return filter
        }
    } else { //Step Detector Passes
        //if Step Detector Passes, all other filters is passed => No filter to display, Show confidence #%
        if(status_bit[1] === '1' && status_bit[2] === '1' && status_bit[3] === '1'){
            return filter
        }
        else if(status_bit[1] !== '1' || status_bit[2] !== '1' || status_bit[3] !== '1'){
            //Case of unexpected result
            filter.push('error')
            console.log('Error: system status filter')
            return filter
        }
    }
    filter.push('error')
    console.log('Error: system status filter')
    return filter
}

function isFilterOn(sysStatus){
    return filterOn(sysStatus).length > 0;
}

function EventView(props){
    const {selectedSensor, organizationId} = props;

    const [limit, setLimit] = useState(10);

    const eventQuery = useQuery({
        queryKey: [`events`, organizationId, selectedSensor.id, limit],
        queryFn: (arg) => {
            return getEvents(organizationId, limit, selectedSensor.id);
        },
        refetchOnWindowFocus: 'true',
        refetchInterval: 5000
    });

    useEffect(() => setLimit(10), [JSON.stringify({id:selectedSensor.id, type:selectedSensor.deviceType})]);

    const dataAvailable = (eventQuery.status === 'success' && eventQuery.data.events);
    const numberOfRecords = dataAvailable ? eventQuery.data.events.length : null;

    if(dataAvailable && numberOfRecords > 0) {
        return (
            <div>
                {
                    eventQuery.data.events.map(
                        event =>
                            <div key={event.id} className="detection">
                                <div>
                                    <b>{event.label}</b>: <Moment calendar>{event.timestamp}</Moment>
                                </div>
                                <div className="latency">
                                    <div>Latency: {event.latency !== null && event.latency > 0 ?
                                        <span>{event.latency} sec </span> : <span>--</span>}</div>
                                    <div>Battery: {event.battery !== null ? <span>{event.battery} %</span> :
                                        <span>--</span>}</div>

                                    {isFilterOn(event.json.sensorStatus.systemStatus) ? 
                                        <div>Filter: {filterOn(event.json.sensorStatus.systemStatus)[0]} </div> 
                                        : (event.confidence !== null) ? 
                                        <div>Confidence: {event.confidence} %</div> 
                                        :<div>Confidence: --</div>
                                    }

                                </div>
                                <div className="latency">
                                    <div>
                                        RSSI Up: {
                                            event.rssiUp !== null ?
                                                <span>{event.rssiUp} dBm </span>
                                                : <span>--</span>
                                        }
                                    </div>
                                    <div>
                                        RSSI Down: {
                                            event.rssiDown !== null ?
                                                <span>{event.rssiDown} dBm </span>
                                                : <span>--</span>
                                        }
                                    </div>
                                </div>
                            </div>
                    )
                }
                {
                    (numberOfRecords === limit) ?
                    <div className="text-center">
                        <Button variant="link" onClick={() => setLimit(lim => lim + 10)}> show more </Button>
                    </div> : null
                }

                <div className="csv-download">
                    <a href={`/organization/${organizationId}/events/?limit=40000&sensorId=${selectedSensor.id}&format=csv&mask=false`}>
                        <Button variant="secondary" size="sm"><FiletypeCsv/> CSV</Button>
                    </a>
                </div>
            </div>
        );
    } else if (dataAvailable) {
        return (
            <NonIdealState
                title={"No Events Detected"}
                description={`No events were detected for sensor ${selectedSensor.deviceId}.`}
            />
        )
    } else {
        return <div><Spinner /></div>;
    }
}

EventView.propTypes = {
    selectedSensor: PropTypes.object,
    organizationId: PropTypes.number
}

export default EventView;