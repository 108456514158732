import React, {useContext, useEffect, useMemo, useState} from "react";
import Moment from "react-moment";
import {
    Sensor,
    archiveGateway,
    getWeatherTypes,
    getWeatherGrid,
    getSensorParamDetails,
    getGatewayParamDetails
} from "../api";
import PropTypes from "prop-types";
import {FiletypeCsv, ArchiveFill, CardText, Geo, Gear} from "react-bootstrap-icons";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {Switch} from "@blueprintjs/core";
import {useMutation, useQuery} from '@tanstack/react-query'
import SelectedOrganizationContextProvider, {
    SelectedOrganizationContext
} from "../context/SelectedOrganizationContextProvider";
import LocationDialog from "./setup/LocationDialog";
import {DeviceListContext} from "../context/DeviceListContextProvider";
import {LinkContainer} from "react-router-bootstrap";
import AppParamDialog from "./setup/AppParamDialog";
import GatewayParamDialog from "./setup/GatewayParamDialog";

function GatewayStatusView(props){
    const {selectedDevice, organization, onArchive, weatherGrids} = props;

    const navigate = useNavigate();

    const {getPageUrl} = useContext(SelectedOrganizationContext);

    const [showArchive, setShowArchive] = useState(false);
    const [permanent, setPermanent] = useState(false);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [ showAppParamDialog, setShowAppParamDialog ] = useState(false);

    const [prevConfirmedSettings, setPrevConfirmedSettings] = useState({});

    const mutation = useMutation(archiveRequest => {
        return archiveGateway(archiveRequest.organizationId, archiveRequest.gatewayId, archiveRequest.permanent);
    })

    const {gatewayMutation} = useContext(DeviceListContext);

    const weatherGrid = useMemo(
        () => weatherGrids.find(grid => grid.id === selectedDevice.nwsGridId),
        [selectedDevice, weatherGrids]
    );

    // Data queries
    const weatherTypesQuery = useQuery({
        queryKey: [`weatherTypes`],
        queryFn: (arg) => {
            return getWeatherTypes();
        }
    });

    // Queries
    const weatherGridQuery = useQuery({
        queryKey: [`currentWeatherGrid`, selectedDevice.nwsGridId],
        queryFn: (arg) => {
            return getWeatherGrid(selectedDevice.nwsGridId, true);
        },
        refetchOnWindowFocus: true,
        enabled: selectedDevice.nwsGridId !== null

    });

    const appParamDetailsQuery = useQuery({
        queryKey: [`gatewayParamDetails`, selectedDevice.id],
        queryFn: (arg) => {
            return getGatewayParamDetails(selectedDevice.id, organization.id);
        },
        enabled: selectedDevice.id !== null
    });


    const weatherDataAvailable = (
        weatherGridQuery.status === 'success' && weatherGridQuery.data !== undefined &&
        weatherTypesQuery.status === 'success' && weatherTypesQuery.data !== undefined
    );

    const initialValues = useMemo(
        () => {
            if(appParamDetailsQuery.data !== undefined) {
                if (appParamDetailsQuery.data.sensor.latestGatewayParamVersion !== null) {
                    return appParamDetailsQuery.data.sensor.latestGatewayParamVersion.desiredValues;
                }
            }
            return undefined;
        },
        [appParamDetailsQuery.data]
    );

    const confirmedValues = useMemo(
        () => {
            if(appParamDetailsQuery.data !== undefined) {
                if (appParamDetailsQuery.data.sensor.latestGatewayParamVersion !== null 
                    && appParamDetailsQuery.data.sensor.latestGatewayParamVersion.deliveredAt === null 
                    && (Object.keys(prevConfirmedSettings).length > 0)){
                    return prevConfirmedSettings
                }
                else if (appParamDetailsQuery.data.sensor.latestGatewayParamVersion !== null && appParamDetailsQuery.data.sensor.latestGatewayParamVersion.deliveredAt !== null) {
                    setPrevConfirmedSettings(appParamDetailsQuery.data.sensor.latestGatewayParamVersion.computedValues);
                    return appParamDetailsQuery.data.sensor.latestGatewayParamVersion.computedValues;
                }
            }
            return undefined;
        },
        [appParamDetailsQuery.data]
    );

    function submitArchive(){
        mutation.mutate({"organizationId":organization.id, "gatewayId": selectedDevice.id, permanent});
    }

    useEffect(() => {
        if (mutation.isSuccess){
            mutation.reset();
            onArchive();
        }
    }, [mutation.isSuccess])

    return (
        <div>
            <Modal show={showArchive}>
                <Form onSubmit={event => {
                    event.preventDefault();
                    submitArchive();
                }}>
                    <ModalHeader>Archive Gateway</ModalHeader>
                    <ModalBody>
                        <p>Archiving a gateway will remove it from view and reporting until activity has been seen
                            again.</p>
                        <Switch onClick={() => setPermanent(prev => !prev)} defaultChecked={permanent}>Suppress future
                            events</Switch>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="secondary" onClick={() => setShowArchive(false)}>Cancel</Button>
                        <Button variant="danger" type="submit">Archive</Button>
                    </ModalFooter>
                </Form>
            </Modal>


            <p>Gateway ID: {selectedDevice.deviceId}</p>
            <p>Last seen: <Moment fromNow>{selectedDevice.lastSeen}</Moment></p>
            {weatherDataAvailable ?
                <p>Temperature: {Math.round(weatherGridQuery.data.getTemperatureFahrenheit(weatherTypesQuery.data), 2)}&deg;F</p>
                : null
            }
            {weatherGrid !== undefined ? <p> Weather Region: {weatherGrid.name} </p> : null}

            <div>
                {
                    initialValues !== undefined &&
                    <GatewayParamDialog
                        initialSettings={initialValues}
                        isDialogOpen={showAppParamDialog}
                        closeDialog={() => setShowAppParamDialog(false)}
                        gateway={selectedDevice}
                        gatewayMutation={gatewayMutation}
                        confirmedSettings={confirmedValues}
                    />
                }
            </div>
            <div className="status-button-bar">

                {/*// href={getPageUrl(`log/${selectedDevice.id}`)}*/}
                <Button
                    variant="secondary" size="sm"
                    onClick={() => navigate(getPageUrl(`log/${selectedDevice.id}`))}>
                    <CardText/> Logs
                </Button>

                {
                    organization.hasRole("admin")
                        ? <Button variant="secondary" size="sm"
                                  onClick={() => setShowArchive(true)}><ArchiveFill/> Archive</Button>
                        : null
                }
                {
                    organization.hasRole("admin")
                        ? <Button variant={"secondary"} onClick={() => setShowAppParamDialog(true)}><Gear/></Button>
                        : null
                }
                {
                    organization.hasRole("admin")
                    && <Button size="sm" variant={selectedDevice.manualLocation ? "primary" : "secondary"}
                               onClick={() => setIsDialogOpen(true)}>
                        <Geo/>
                    </Button>
                }
                {isDialogOpen && <LocationDialog
                    device={selectedDevice}
                    deviceMutation={gatewayMutation}
                    isDialogOpen={isDialogOpen}
                    closeDialog={() => setIsDialogOpen(false)}
                />}
            </div>
        </div>
    );
}

GatewayStatusView.propTypes = {
    selectedDevice: PropTypes.object,
    organization: PropTypes.object,
    onArchive: PropTypes.func,
    weatherGrids: PropTypes.array
}
export default GatewayStatusView